import * as React from 'react';

import { Text, BlogImage, BlogLink } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import ivanaS1 from '../../../images/educational-corner/blog22/slika-logopedice-Ivane.png';
import ivanaS2 from '../../../images/educational-corner/blog27/ivana-sabic-logoped.jpg';
import kidsLearning from '../../../images/educational-corner/blog27/djeca-zajedno-uce.jpg';
import kidAndKokolingo1 from '../../../images/educational-corner/blog27/djevojcica-vjezba-uz-Kokolingo1.jpg';
import kidAndKokolingo2 from '../../../images/educational-corner/blog27/djevojcica-vjezba-uz-Kokolingo2.jpg';

const NoTheoryJustPraxis = () => {
  return (
    <PageNew
      title="KOKOLINGO - Ovoga puta bez teorije, samo praksa"
      description="S logopedinjom Ivanom Šabić razgovarali smo o artikulacijskim 
      poremećajima djece predškolske i školske dobi. Osim općenitih pojašnjenja 
      tog problema, bilo je zanimljivo slušati i o iskustvima iz prakse, 
      pravim primjerima s terena, kao i o pogledu na situaciju u Hrvatskoj, 
      koju je ponekad teško iščitati samo iz statistika."
      headline="Ovoga puta bez teorije, samo praksa"
    >
      <Text as="p">
        S logopedinjom Ivanom Šabić razgovarali smo o artikulacijskim
        poremećajima djece predškolske i školske dobi. Osim općenitih
        pojašnjenja tog problema, bilo je zanimljivo slušati i o iskustvima iz
        prakse, pravim primjerima s terena, kao i o pogledu na situaciju u
        Hrvatskoj, koju je ponekad teško iščitati samo iz statistika.
      </Text>
      <BlogImage
        imgAlt="Slika prikazuje logopedicu Ivanu Sabic"
        imgTitle="Logopedica Ivana Sabic"
        imgSrc={ivanaS1}
      />
      <Text as="h2">Tko upućuje djecu logopedu?</Text>
      <Text as="p">
        Logopedi znaju imati upita za procjene već oko druge ili treće godine
        djetetova života, kada se roditelji obraćaju pedijatrima ako su
        zabrinuti zbog nečega, a oni ih upućuju na logopede. Kod ovakvih ranih
        slučajeva obično je problem složeniji i ne radi se samo o
        artikulacijskim poremećajima. Inače, djelatnici u vrtićima dobro su
        educirani o kalendaru razvoja dječjega govora i koja su moguća
        odstupanja. Također, i sami su roditelji posljednjih godina dosta
        upoznati s tom tematikom, pitaju, međusobno pričaju, uspoređuju
        iskustva.
      </Text>
      <Text as="h2">Negiranje ili suradnja?</Text>
      <Text as="p">
        Na pitanje jesu li roditelji ponekad skloni negirati probleme
        artikulacijskih poremećaja, naša sugovornica odgovara da to kod govornih
        artikulacijskih teškoća baš i nije slučaj. Takve se stvari češće
        događaju kod jezičnih poremećaja, na primjer kada razvoj jezika malo
        više kasni, kada rečenica nije razvijena do treće godine, kod poremećaja
        iz spektra autizma i sl.
      </Text>
      <Text as="p">
        Također, ima slučajeva kada djeca već dođu do predškolske dobi, a nije
        se na vrijeme reagiralo. Obično se ovdje radi o roditeljima koji misle
        da će teškoća s vremenom proći, da će se neki glas spontano razviti,
        izgladiti do škole. Međutim, često ne bude tako, jer se greške s
        vremenom fiksiraju. U ovim slučajevima predugo čekanje dovelo je do
        učvršćivanja pogrešnoga izgovora. Na primjer, dječje zamjenjivanje glasa
        r glasovima j ili l može se prirodno s vremenom i ispraviti, ali ako se
        radi o nekim drugim artikulacijskim poremećajima, nepravilan izgovor
        često zna ostati i učvrstiti se.
      </Text>
      <Text as="p">
        Pitali smo i koliko dobro vrtići i škole danas prepoznaju artikulacijske
        poremećaje kod djece. I na to pitanje odgovor daje razloga za optimizam.
        Djelatnici su educirani i dobro prepoznaju problem. Danas je
        osviještenost puno veća nego što je to bila nekada. Odgojitelji znaju
        kako bi dijete u predškolskim okvirima trebalo govoriti, a puno se priča
        i o tome što bi dijete trebalo znati pri upisu u prvi razred.
      </Text>
      <Text as="p">
        Artikulacijski poremećaj može biti izolirana teškoća, kada je cijeli
        psihofizički razvoj uredan, i to je lakša varijanta, a može se, naravno,
        pojavljivati i uz druge teškoće, npr. kada je govor nerazumljiv okolini,
        ili problem artikulacije može biti popraćen i drugim teškoćama, recimo
        slušnim. Kada dijete ima oštećenje sluha, imat će, naravno, i
        artikulacijski problem. Ovdje roditelji već otprije znaju za taj problem
        i znaju da trebaju vježbe pa ta djeca već budu upućena logopedu.
      </Text>
      <BlogImage
        imgAlt="Skolovanje i artikulacijske poteskoce"
        imgTitle="Djeca zajedno uce"
        imgSrc={kidsLearning}
      />
      <Text as="p">
        Najveći je problem s onima koji smatraju da neka artikulacijska teškoća
        nije problem koji se ne bi sam po sebi ispravio, a greška se s vremenom
        fiksira. Poslije, kada u školi treba pisati diktate, kada treba učiti
        čitati, nastaju problemi, jer greške nepravilnog izgovora reflektiraju
        se i na ova područja.
      </Text>
      <Text as="h2">Najvažnije je osvijestiti problem</Text>
      <Text as="p">
        Našu smo sugovornicu pitali i kako tumači veću osviještenost današnjih
        roditelja o ovim problemima. Pripisuje li se to možda novim načina
        informiranja danas? Smatra da tu ima raznih utjecaja, a prvenstveno su
        to internet i drugi mediji. Dobar su primjer Facebook grupe u koje se
        uključuju mame, međusobno se konzultiraju, razmjenjuju iskustva, traže
        preporuke. I roditelji danas malo više prate što se događa, i ako ih
        netko upozori, poduzimaju odgovarajuće korake. Uvijek ima i tužnih priča
        o pedagoškoj zapuštenosti, ali one su ipak rijetke i ovdje pričamo o
        uobičajenom stanju.
      </Text>
      <Text as="h2">Trendovi u hrvatskoj</Text>
      <Text as="p">
        Jako nas je zanimalo da nam stručnjakinja koja svakodnevno radi s djecom
        kaže i koja je njezina procjena raširenosti artikulacijskih poremećaja u
        Hrvatskoj među djecom predškolske dobi. Odgovor je zaista iznenađujući.
        Prema njezinoj procjeni, prije je učestalost bila oko 10 posto, a sada
        je to 20 do 30 posto. Po njezinom mišljenju, na to je najviše utjecao
        internet i sve veće korištenje računala i mobitela. Rijetki su danas
        roditelji koji su od djece sklonili mobitele. Sve se manje pričaju
        priče, a one su za djecu jako važne. Djeca kojima se ne pričaju priče
        imaju puno lošiji vokabular i lošiji govor, jer dijete kroz priče
        ponavlja, uči.
      </Text>
      <BlogImage
        imgAlt="Slika prikazuje logopedicu Ivanu Sabic"
        imgTitle="Logopedica Ivana Sabic"
        imgSrc={ivanaS2}
      />
      <Text as="h2">Školovanje i artikulacijske poteškoće</Text>
      <Text as="p">
        Zanimalo nas je i kakva su iskustva djece koja su već u školu došla s
        problemom izgovora. Odgovor koji smo dobili upućuje na ozbiljnost i
        važnost bavljenja artikulacijskim teškoćama od samoga početka. Takvoj
        djeci to je dosta veliki problem. Kada, recimo, uče pisati, ona
        zamjenjuju glasove sa slušno sličnim glasovima. Ako, na primjer, ne mogu
        izgovoriti glas r i zamjenjuju ga glasovima l ili j, tako ga i pišu.
        Česte su i slušne zamjene s sa š i lj sa j. Znači, dijete piše onako
        kako ono samo izgovara.
      </Text>
      <BlogImage
        imgAlt="Djevojcica uci uz vjezbenicu Kokolingo"
        imgTitle="Djevojcica uci uz vjezbenicu Kokolingo"
        imgSrc={kidAndKokolingo1}
      />
      <Text as="h2">Kokolingo iz prve ruke</Text>
      <Text as="p">
        U drugom dijelu razgovora pozabavili smo se i digitalnom logopedskom
        vježbenicom <BlogLink>Kokolingo</BlogLink>, na kojoj je i magistra Šabić
        bila suradnica te je i njezino iskustvo utkano u Kokolingove vježbe kroz
        igru. Zato je jako vrijedno čuti savjete na koje se sve načine može
        koristiti, kako ga koristiti pravilno te zašto nije dobro olako ga
        shvatiti kao običnu igricu za opuštanje i zabavu.
      </Text>
      <Text as="p">
        Djeca <BlogLink>Kokolingo</BlogLink> dosta shvaćaju kao igricu. Ponekad
        roditelji kažu kako djeci Kokolingo zna dosaditi za pet ili deset dana,
        ali tu je jako važno da roditelj ima nadzor te cijelo korištenje mora
        imati smisla. Također, mora se uvijek koristiti u određenom dijelu dana,
        a ne kada se dijete samo sjeti. Roditelji ne smiju dijete pustiti da
        vježba samo.
      </Text>
      <Text as="p">
        Kod starije djece to se jako lijepo uvježba, a što se više s njima radi,
        to oni već počnu neke stvari obavljati samostalno (npr. ocjenjuju se).
        Prvašići pak prepisuju riječi. Tako se vježba i izgovor i pisanje.{' '}
        <BlogLink>Kokolingo</BlogLink> je jako dobar i kod ponavljanja rečenica.
        Djeca s govorno-jezičnim problemima ne mogu, na primjer, ponoviti cijelu
        rečenicu, često ispuste male riječi poput priloga, pomoćnih glagola,
        čestica, a s Kokolingom vježbaju govorno i slušno pamćenje. Čak mogu
        vježbati i čitanje u rubrici Razumijem što čitam. Dijete pročita
        rečenicu, gleda sličicu i to mu na određeni način pomaže. Manji mu je
        otpor i zabavnije je raditi.
      </Text>
      <Text as="p">
        Roditeljima treba malo pokazati kako se aplikacija koristi. Tek kada se
        s djetetom radi pred njima, vide i iznenade se što se sve može. Treba im
        dati primjer. Pokaže im se da mogu i prepisivati riječi, da mogu
        vježbati slušnu sintezu i analizu. Aplikacija je jako dobra kod
        automatizacije, kada dijete već nauči kako treba pravilno izgovarati
        neki glas, jer ima puno riječi, puno rečenica koje pomažu da se učvrsti
        taj glas.
      </Text>
      <Text as="h2">Eksperiment s kokolingom</Text>
      <Text as="p">
        Kokolingo je korišten i kod nekih drugih teškoća, za koje nije izvorno
        namijenjen. Tako je gđa Šabić isprobala Kokolingo u radu s čovjekom koji
        je imao moždani udar, a kao posljedicu toga, Brocinu afaziju, kada je
        razumijevanje kod pojedinca dobro, ali govor je narušen. Radili su s
        materijalima za djecu i pomalo se i šalili na račun toga. Pokazalo se da
        je vizualni oslonac jako bitan. Ove osobe imaju problema s izgovorom
        glasova, a naglašavanje određenog glasa u Kokolingu aktivira slušanje i
        olakšava napredak. Ispalo je odlično i oboje su bili oduševljeni. Naime,
        u radu s afazijom jako nedostaje materijala, a u Kokolingu je sve na
        jednome mjestu, spremno za trenutačnu upotrebu. To je jako olakšalo rad,
        ali i potaknulo na neke nove ideje.
      </Text>
      <BlogImage
        imgAlt="Djevojcica uci uz vjezbenicu Kokolingo"
        imgTitle="Djevojcica uci uz vjezbenicu Kokolingo"
        imgSrc={kidAndKokolingo2}
      />
      <Text as="h2">Pomažemo li logopedima?</Text>
      <Text as="p">
        Na kraju ovog razgovora pitali smo i na koje sve načine Kokolingo pomaže
        logopedima i u čemu im je olakšao rad. Naša sugovornica kaže da uporabom
        aplikacije dosta štedi svoj glas. Dijete ponavlja za gusarom. Gusar
        produljuje neke glasove, naglašava ih. Ispod sličice su napisane riječi,
        a djeca koja već znaju slova, mogu ih i zapisivati. Tako spontano
        vježbaju i za diktat, koji često predstavlja problem, jer artikulacijske
        teškoće utječu i na pisani izričaj. Kod čitanja i razumijevanja puno
        pomažu vizualni materijali. Za rad s djecom važno je da ih nešto
        motivira. Vježbe brzo dosade kada logopedinja stalno ponavlja sama,
        gledaju se u ogledalo i dijete ju imitira, a Kokolingo dosta produljuje
        vrijeme rada. Tako se, na primjer, u 45 minuta na satu s predškolcima
        uspije odraditi 20 „pravih“ minuta. Zato Kokolingo značajno produljuje
        vrijeme rada s djetetom. Također, zadaci su različiti. Međutim,
        najvažnije je to što se jača i govorna razina i jezični dio. Kada se
        rečenice ponavljaju točno, sa svim riječima, onda to jača jezični
        razvoj.
      </Text>
      <Text as="p">
        Zato, na kritike da bi uvođenje digitalnih sadržaja moglo značiti
        degradiranje struke, odgovor je - Kokolingo je napravljen upravo kao
        pomoć logopedu i pomoć roditeljima. Logopedi inače imaju bilježnice i
        djetetu zadaju domaće radove, a ovako imaju sve odmah spremno. Ne moraju
        sami vježbati s djetetom, nego nadzirati ga. To jako ubrzava proces
        ispravljanja glasa. Kokolingo je u radu samo pomoć, a i kada roditelji
        vide kako logopedinja radi s djetetom, jako se zainteresiraju za sve
        mogućnosti aplikacije. Naravno, uvijek će postojati određeni broj djece
        koja neće prihvaćati vježbanje uz Kokolingo i to je sasvim u redu.
      </Text>
      <Text as="p">
        U svakom slučaju, logopeda se ne može zamijeniti, on mora
        dijagnosticirati, pogledati o čemu se radi. Mora biti i u kontaktu s
        roditeljima koji koriste Kokolingo i objasniti im na koji način će ga
        koristiti. To i jest bio cilj ove aplikacije. Roditelji uvijek
        razgovaraju s nekime što bi trebalo raditi s djetetom - s logopedom,
        defektologom, odgajateljem, a Kokolingo je stvarno napravljen temeljito,
        od logatoma i riječi, do rečenica i teksta, sve je na jednome mjestu.
      </Text>
      <SectionBlog />
    </PageNew>
  );
};

export default NoTheoryJustPraxis;
